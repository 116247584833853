import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Image } from '@squareup/dex-ui-shared-image';
import React, { FC } from 'react';

interface MarkdownImageProps {
  src: string;
  alt: string;
  title?: string | undefined;
  width?: string;
  loop?: boolean;
  muted?: boolean;
  preload?: string;
  autoplay?: boolean;
  height?: string | undefined;

  // The priority of the image. If true, we'll tell the next/image component to load it immediately,
  // if false, it will lazy load. Images above the fold should be prioritized.
  priority?: boolean | undefined;
}

const videoExtensions = ['mp4', 'webm'];

/**
 * A component that handles the `![alt](src)` markdown syntax, which
 * corresponds to an image.
 *
 * In this case, we've also extended it to support videos too with the same syntax, just looking at the extension
 *
 * @param props All markdown image or video props
 * @returns
 */
const MarkdownImage: FC<MarkdownImageProps & NullableClassName> = ({
  src = '',
  alt,
  title,
  width,
  height,
  loop = true,
  muted = true,
  preload = 'preload',
  autoplay = true,
  priority = false,
  className,
}) => {
  for (const ext of videoExtensions) {
    if (src.endsWith(`.${ext}`)) {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          className={className}
          autoPlay={autoplay}
          loop={loop}
          muted={muted}
          preload={preload}
          width={width || '400'}
        >
          <source src={src} type={`video/${ext}`}></source>
        </video>
      );
    }
  }

  return (
    <Image
      src={src}
      alt={alt}
      title={title}
      priority={priority}
      {...{ width: width as `${number}`, height: height as `${number}` }}
    />
  );
};

const schema = {
  render: 'MarkdownImage',
  children: ['inline'],
  attributes: {
    src: { type: String, required: true, default: '' },
    alt: { type: String, required: true, default: '' },
    title: { type: String, required: false },
    width: { type: String, required: false },
    height: { type: String, required: false },
    priority: { type: Boolean, required: false },
  },
};

const image: MarkdownNode = {
  node: {
    nodeType: 'image',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownImage,
  },
};

export { image, MarkdownImage };
