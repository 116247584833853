import CircleArrows from '@square-icons/react/16/UI/circle-arrows';
import LinkIcon from '@square-icons/react/16/UI/link';
import XIcon from '@square-icons/react/16/UI/x';
import CopyIcon from '@squareup/dex-icons/dex/action/Copy';
import { UpDownLeftRight } from '@squareup/dex-icons/dex/arrow';
import { ChevronDown } from '@squareup/dex-icons/dex/control';
import {
  Curl,
  Csharp,
  Go,
  Java,
  Nodejs,
  Php,
  Python,
  Ruby,
} from '@squareup/dex-icons/dex/language';
import { CodeExampleLanguage } from '@squareup/dex-types-oas';
import { OnClickProp, NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { MarketActivityIndicator } from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import React, {
  FunctionComponent,
  ReactNode,
  SVGProps,
  useCallback,
  useState,
} from 'react';

import { IconButton } from '../IconButton';
import {
  NativeDropdown,
  NativeDropdownRow,
  nativeDropdownRowStyles,
} from '../NativeDropdown';

import styles from './code-block-action-button-group.module.css';

type IconProps = SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>;
const codeLanguageIconMap = new Map<CodeExampleLanguage, React.FC<IconProps>>([
  ['curl', Curl],
  ['java', Java],
  ['csharp', Csharp],
  ['php', Php],
  ['javascript', Nodejs],
  ['python', Python],
  ['ruby', Ruby],
  ['go', Go],
]);

interface CodeBlockActionButtonGroup {
  children: ReactNode | ReactNode[];
}

interface CodeBlockCopyButton {
  code?: string;
}

const copyText = {
  default: 'Copy',
  copied: 'Copied!',
};

const copyTooltipTimeoutMs = 2000;

const CodeCopyButton: FunctionComponent<
  CodeBlockCopyButton & NullableClassName & OnClickProp
> = ({ className, code, onClick }) => {
  const [tooltipText, setTooltipText] = useState(copyText.default);

  const memoizedOnClickHandler = useCallback(
    async (e: React.MouseEvent) => {
      setTooltipText(copyText.copied);
      setTimeout(() => {
        setTooltipText(copyText.default);
      }, copyTooltipTimeoutMs);

      if (code) {
        await navigator.clipboard.writeText(code);
      }

      onClick && onClick(e);
    },
    [onClick, code]
  );

  return (
    <IconButton
      aria-label="Copy code"
      trackingId="copy-code-block"
      data-testid="copy-code-block"
      className={clsx(className)}
      noHoverStyling={true}
      popoverPlacement="bottom"
      icon={
        <CopyIcon className={clsx(commonIconStyles['icon-color'])}></CopyIcon>
      }
      tooltipText={tooltipText}
      onClick={memoizedOnClickHandler}
    ></IconButton>
  );
};

const CodeExpandButton: FunctionComponent<NullableClassName & OnClickProp> = ({
  className,
  onClick,
}) => {
  return (
    <IconButton
      aria-label="Expand code"
      trackingId="expand-code-block"
      data-testid="expand-code-block"
      noHoverStyling={true}
      popoverPlacement="bottom"
      className={clsx(className)}
      icon={
        <UpDownLeftRight
          className={clsx(commonIconStyles['icon-color'])}
        ></UpDownLeftRight>
      }
      tooltipText={'Expand'}
      onClick={onClick}
    ></IconButton>
  );
};

const CodeCloseButton: FunctionComponent<NullableClassName & OnClickProp> = ({
  className,
  onClick,
}) => {
  return (
    <IconButton
      aria-label="Close code"
      trackingId="close-code-block"
      data-testid="close-code-block"
      noHoverStyling={true}
      className={clsx(className)}
      icon={<XIcon className={clsx(commonIconStyles['icon-color'])}></XIcon>}
      tooltipText={'Close'}
      onClick={onClick}
    ></IconButton>
  );
};

const CodeResetButton: FunctionComponent<NullableClassName & OnClickProp> = ({
  className,
  onClick,
}) => {
  return (
    <IconButton
      aria-label="Reset code"
      trackingId="reset-code-block"
      data-testid="reset-code-block"
      noHoverStyling={true}
      className={clsx(className)}
      icon={
        <CircleArrows
          className={clsx(commonIconStyles['icon-color'])}
        ></CircleArrows>
      }
      tooltipText={'Reset'}
      onClick={onClick}
    ></IconButton>
  );
};

const CodeShareButton: FunctionComponent<
  NullableClassName & OnClickProp & { shareLink?: string }
> = ({ className, onClick, shareLink }) => {
  const [tooltipText, setTooltipText] = useState('Share');

  const memoizedOnClickHandler = useCallback(
    async (e: React.MouseEvent) => {
      setTooltipText('Copied!');
      setTimeout(() => {
        setTooltipText('Share');
      }, copyTooltipTimeoutMs);

      if (shareLink) {
        await navigator.clipboard.writeText(shareLink);
      }

      onClick && onClick(e);
    },
    [onClick, shareLink]
  );

  return (
    <IconButton
      aria-label="Share code"
      trackingId="share-code-block"
      data-testid="share-code-block"
      noHoverStyling={true}
      className={clsx(className)}
      icon={
        <LinkIcon className={clsx(commonIconStyles['icon-color'])}></LinkIcon>
      }
      tooltipText={tooltipText}
      onClick={memoizedOnClickHandler}
    ></IconButton>
  );
};

type CodeDropdownButtonItem<T> = { name: string; value: string } & T;

interface CodeDropdownButtonProps<T = {}> {
  selected: string;
  isLoading?: boolean | undefined;
  items?: Array<CodeDropdownButtonItem<T>> | undefined;
  onSelected: (item: CodeDropdownButtonItem<T>) => void;
  buttonTestId?: string;
  listTestId?: string;
}

const CodeDropdownButton = <T,>({
  className,
  items,
  isLoading = false,
  selected,
  onSelected,
  buttonTestId,
  listTestId,
}: CodeDropdownButtonProps<T> & NullableClassName) => {
  const memoizedOnSelected = useCallback(
    (item: CodeDropdownButtonItem<T>) => {
      onSelected(item);
    },
    [onSelected]
  );

  return (
    <Box padding={{ horizontal: '1x' }}>
      <NativeDropdown
        aria-label="Coding language dropdown selection"
        aria-describedby="A dropdown to select what coding language the request code block should be in."
        trackingId="code-block-actions-list"
        buttonTestId={buttonTestId}
        listTestId={listTestId}
        className={clsx(className)}
        selected={selected}
        trigger={
          <>
            {!isLoading && (
              <Paragraph10 weight="semi-bold" margin={{ right: '0.5x' }}>
                {selected}
              </Paragraph10>
            )}
            {isLoading && (
              <MarketActivityIndicator
                className={styles['activity-indicator'] as string}
                size="small"
              />
            )}
            <ChevronDown className={clsx(commonIconStyles['icon-color'])} />
          </>
        }
      >
        {items &&
          items.map((item) => {
            const Icon = codeLanguageIconMap.get(
              item.value as CodeExampleLanguage
            );
            return (
              <NativeDropdownRow
                trackingId={`select-code-${item.name}`}
                key={item.name}
                value={item.name}
                onSelected={() => memoizedOnSelected(item)}
                className={styles['select-code-option']}
              >
                <Box className={styles['select-code-row']}>
                  <Box className={styles['code-lang-icon-container']}>
                    {Icon && <Icon className={styles['code-lang-icon']} />}
                  </Box>
                  <Paragraph10
                    weight="semi-bold"
                    className={nativeDropdownRowStyles.primary}
                  >
                    {item.name}
                  </Paragraph10>
                </Box>
              </NativeDropdownRow>
            );
          })}
      </NativeDropdown>
    </Box>
  );
};

const CodeBlockButtonActionGroup: FunctionComponent<
  CodeBlockActionButtonGroup & NullableClassName
> = ({ className, children }) => {
  return (
    <Box className={clsx(styles['button-group'], className)}>
      {Array.isArray(children) ? (
        children.map((child, i) => {
          return <Box key={i}>{child}</Box>;
        })
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

export {
  CodeBlockButtonActionGroup,
  CodeCopyButton,
  CodeExpandButton,
  CodeDropdownButton,
  CodeCloseButton,
  CodeResetButton,
  CodeShareButton,
  type CodeDropdownButtonItem,
};
